import { useNavigate } from '@solidjs/router'
import { Component, createResource, Show } from 'solid-js'
import Button from '~/components/button/button'
import Landing from '~/components/landing/landing'
import SmallButton from '~/components/small-button/small-button'
import { SubscriptionGateProps } from '~/components/subscription-gate/subscription-gate.interfaces'
import styles from '~/components/subscription-gate/subscription-gate.module.scss'
import { canAccessFeature, requiredSubcription } from '~/services/database/rights'
import { translations } from '~/translations'

const SubscriptionGate: Component<SubscriptionGateProps> = ({ requiredRole, children }) => {
  const [hasRequiredRole] = createResource(requiredRole, canAccessFeature)
  const roleTranslation = requiredSubcription(requiredRole)
  const navigate = useNavigate()
  const onSubscribe = () => navigate('/subscribe')
  const onBack = () => navigate(-1)

  return (
    <Show
      when={hasRequiredRole}
      fallback={(
        <Landing>
          <div class={styles.subscriptionGate}>
            <div class={styles.title}>
              {translations().editor.steps[requiredRole]}
            </div>
            <div class={styles.description}>
              {translations().subscriptions.rights(roleTranslation)}
            </div>
            <Button
              onClick={onSubscribe}
              style='lagoon'
            >
              {translations().header.subscribe}
            </Button>
            <SmallButton onClick={onBack}>
              {translations().general.actions.back}
            </SmallButton>
          </div>
        </Landing>
      )}
    >
      {children}
    </Show>
  )
}

export default SubscriptionGate
